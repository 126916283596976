import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type IProps = {
	description?: string,
	lang?: string,
	meta?: any[],
	title?: string,
}

function SEO(props: IProps) {
	const { site } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            title
            description
						keywords
            company {
              name
            }
          }
        }
      }
    `
	)

	const lang = props.lang || 'en';
	const meta = props.meta || [];
	const description = props.description || '';

	const metaDescription = description || site.siteMetadata.description

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={props.title || site.siteMetadata.title}
			titleTemplate={props.title ? `%s | ${site.siteMetadata.title}` : site.siteMetadata.title}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					name: "keywords",
					content: site.siteMetadata.keywords.join(","),
				},
				{
					property: `og:title`,
					content: props.title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.company.name,
				},
				{
					name: `twitter:title`,
					content: props.title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}
		/>
	)
}



export default SEO
